/*
Template Name: Lexa - Responsive Bootstrap 4 Admin Dashboard
Author: Themesbrand
Version: 1.0.0
Website: https://themesbrand.com/
Contact: themesbrand@gmail.com
File: Main Css File
*/

//Fonts
@import "custom/fonts/fonts";

//Core files
@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "variables";
@import "../../../node_modules/bootstrap/scss/mixins.scss";

// Structure
@import "custom/structure/general";
@import "custom/structure/topbar";
@import "custom/structure/page-head";
@import "custom/structure/footer";
@import "custom/structure/right-sidebar";
@import "custom/structure/vertical";
@import "custom/structure/horizontal-nav";
@import "custom/structure/layouts";

// Components
@import "custom/components/waves";
@import "custom/components/avatar";
@import "custom/components/helper";
@import "custom/components/preloader";
@import "custom/components/forms";
@import "custom/components/widgets";
@import "custom/components/demos";
@import "custom/components/print";


// Plugins
@import "custom/plugins/custom-scrollbar";
@import "custom/plugins/calendar";
@import "custom/plugins/dragula";
@import "custom/plugins/session-timeout";
@import "custom/plugins/range-slider";
@import "custom/plugins/sweatalert2";
@import "custom/plugins/parsley";
@import "custom/plugins/select2";
@import "custom/plugins/switch";
@import "custom/plugins/datepicker";
@import "custom/plugins/form-editors";
@import "custom/plugins/form-upload";
@import "custom/plugins/summernote";
@import "custom/plugins/responsive-table";
@import "custom/plugins/table-editable";
@import "custom/plugins/flot";
@import "custom/plugins/morris";
@import "custom/plugins/c3";
@import "custom/plugins/chartist";
@import "custom/plugins/sparkline-chart";
@import "custom/plugins/google-map";
@import "custom/plugins/vector-maps";


// Pages
@import "custom/pages/authentication";
@import "custom/pages/email";
@import "custom/pages/directory";
@import "custom/pages/timeline";

@import url('~tingle.js/dist/tingle.css');
@import url('~cropperjs/dist/cropper.css');

.tingle-modal {
    z-index: 999999;
}

.chat-message-right, .chat-message-left {
    display: flex;
    flex-direction: column-reverse !important;
    align-items: end;
}

.chat-active {
    color: white !important;
    background-color: #a31a5c !important;
}

.chat-online {
    color: #34ce57
}

.chat-offline {
    color: #e4606d
}

.chat-messages {
    display: flex;
    flex-direction: column-reverse !important;
    overflow-y: scroll;

    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
}

.chat-message-left, .chat-message-right {
    display: flex;
    flex-shrink: 0
}

.chat-message-left {
    margin-right: auto
}

.chat-message-right {
    flex-direction: row-reverse;
    margin-left: auto
}

.avatar-chat {
    width: 36px;
    height: 36px;
}

.grid-container, .user-card-container {
    margin-top: 48px;

    > * {
        padding: 48px 0;
        border-top: 1px solid #DFE2E6;
    }
}

.hub-profile {
    .hub-profile-header {
        position: relative;
        height: 227px;
        border-radius: 8px;
        background: url("../images/hub-profile-header.png");
        background-size: cover;
        margin-bottom: 48px;

        > img {
            position: absolute;
            border: 7px solid #FFFFFF;
            border-radius: 31px;
            width: 182px;
            height: 182px;
            bottom: -32px;
            left: 32px;
        }
    }

    .grid-container {
        h1 {
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            color: #2D3C52;
        }

        p {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #1E1E1E;
        }
    }

    .row {
        .col:first-child {
            h1 {
                font-weight: 500;
                font-size: 20px;
                line-height: 24px;
                color: #1E1E1E;
            }

            h2 {
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;

                color: #1E1E1E;
            }
        }

        .col:not(:first-child) {
            display: flex;
            flex-direction: column;

            > * {
                margin-bottom: 10px;
                font-size: 16px;
                line-height: 19px;
                color: #1E1E1E;
            }
        }
    }

}

.user-card-container {
    width: 100%;
    display: flex;
    flex-direction: column;

    .user-card {
        padding: 34px 0;

        img {
            border-radius: 50%;
            width: 64px;
            height: 64px;
        }

        .row {
            margin-bottom: 20px;
        }

        h1 {
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            color: #1E1E1E;
        }

        h2 {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;

            color: #1E1E1E;
        }

        .actions {
            display: flex;
            flex-direction: column;

            a {
                margin-bottom: 10px;
                font-size: 16px;
                line-height: 19px;
                color: #1E1E1E;
            }
        }

        ul {
            list-style: none;

            margin: 0;
            padding: 0;

            li {
                font-weight: 400;
                font-size: 16px;
                line-height: 23px;
                color: #1E1E1E;

                &:not(:last-child) {
                    margin-bottom: 30px;
                }
            }
        }
    }
}

.questionare {
    h1 {
        font-weight: 600;
        font-size: 16px;
        line-height: 32px;
        margin: 16px 0 8px 0;

        color: #1E1E1E;
    }
}

.options {
    display: flex;
    flex-wrap: wrap;

    > * {
        margin-top: 10px;

        &:not(:last-child) {
            margin-right: 30px;
        }

        padding: 12px 38px;

        font-size: 14px;
        line-height: 17px;
        text-align: center;
    }
}